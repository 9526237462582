import { RootState } from "@/redux/configureStore";
import { Input, Typography } from "@zigops-material/react";
import { useDispatch, useSelector } from "react-redux";
import { setCustomerPhoneNumber, setCustomerPhoneNumberError } from "@/redux/features/payrollFunding/customer-code-funding-method-form-data";

const CustomerPhoneNumber = () => {
    const { customerPhoneNumber: payrollFundingPhoneNumber, customerPhoneNumberError: payrollFundingPhoneNumberError } =
        useSelector((state: RootState) => state.app_reducer.customerCodeFundingMethodFormData);
    const dispatch = useDispatch();

    const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newPhoneNumber = e.currentTarget.value;
        if (!newPhoneNumber || newPhoneNumber === "+260") {
            dispatch(setCustomerPhoneNumber("+260"));
            return;
        }
        else if (newPhoneNumber.startsWith("+260")) {
            dispatch(setCustomerPhoneNumber(newPhoneNumber));
        }

        dispatch(setCustomerPhoneNumberError(""));
    }

    return (
        <div className="flex flex-col-reverse w-[100%] [@media(min-width:961px)]:flex-row h-[100%] [@media(min-height:900px)]:h-[80%]  gap-4">
            <div className="h-[100%] w-[100%] gap-4 p-5 [@media(min-width:461px)]:px-10 flex flex-col animate-fade-up rounded-md border border-gray-300 bg-white dark:border-gray-750 dark:bg-gray-850">
                <Typography className="text-2xl mt-10 text-gray-500">Enter a phone number that will be used for funding payroll</Typography>
                <Typography>We will use this phone number (together with the name in the next step) to generate a unique code that you can use when depositing payroll funds.</Typography>
                <div className="w-[100%]">
                    <Input
                        className="w-[100%] [@media(min-width:590px)]:w-[50%] text-gray-500 dark:text-gray-100"
                        value={payrollFundingPhoneNumber}
                        name="phone"
                        onChange={handlePhoneNumberChange}
                        variant="standard"
                        type="tel"
                        placeholder="e.g. 0971234567"
                        error={!!payrollFundingPhoneNumberError}
                    />
                    {payrollFundingPhoneNumberError &&
                        < Typography className="text-red-300">
                            {payrollFundingPhoneNumberError}
                        </Typography>
                    }
                </div>
            </div>
        </div>
    );
}

export default CustomerPhoneNumber;