// Importing necessary modules and types
import { EmployeeCompensationCalculation } from "@/types/Payroll";
import { createSlice, PayloadAction } from "@reduxjs/toolkit"; // Importing createSlice and PayloadAction from Redux Toolkit

// Defining types for payment options
interface PaymentTypes {
  type: string;
  label: string;
}

interface PayrollApproversList {
  employeeId: string;
  name: string;
  email: string;
  approvalChoice: string;
}

// Defining the shape of the form data state
interface formDataState {
  payrollName: string;
  payrollPayDay: number;
  payrollInitialPaymentDate: any;
  howOftenToPayEmployees: PaymentTypes;
  automaticallyApproveWhenDue: PaymentTypes;
  deductionNotification: PaymentTypes;
  whoToPay: PaymentTypes;
  selectedDepartments: { departmentId: string }[];
  approvers_list: PayrollApproversList[];
  employees_list: EmployeeCompensationCalculation[];
  noEmployeesCanBeAddedToPayroll: boolean;
  isLoadingEmployees: boolean;
}

// Initialize the initial state of the form data
const initialState: formDataState = {
  payrollName: "",
  payrollPayDay: 1,
  payrollInitialPaymentDate: "",
  howOftenToPayEmployees: {
    type: "",
    label: "",
  },
  automaticallyApproveWhenDue: {
    type: "",
    label: "",
  },
  deductionNotification: {
    type: "",
    label: "",
  },
  whoToPay: {
    type: "",
    label: "",
  },
  selectedDepartments: [],
  approvers_list: [],
  employees_list: [],
  noEmployeesCanBeAddedToPayroll: false,
  isLoadingEmployees: false
};

// Creating a Redux slice for managing payroll form data
export const payrollFormDataSlice = createSlice({
  name: "payroll-onboarding-form-data",
  initialState,
  reducers: {
    // Reducer to set payroll name
    setPayrollName: (state: formDataState, action: PayloadAction<any>) => {
      state.payrollName = action.payload.payrollName;
    },
    // Reducer to set payroll payday
    setPayrollPayDay: (state: formDataState, action: PayloadAction<any>) => {
      state.payrollPayDay = action.payload.payrollPayDay;
    },
    setPayrollInitialPaymentDate: (state: formDataState, action: PayloadAction<any>) => {
      state.payrollInitialPaymentDate = action.payload.payrollInitialPaymentDate;
    },
    // Reducer to set how to pay employees
    setHowOftenToPayEmployees: (state: formDataState, action: PayloadAction<any>) => {
      state.howOftenToPayEmployees = action.payload.howOftenToPayEmployees;
    },
    // Reducer to set automatic approval details
    setAutomaticallyApproveWhenDue: (state: formDataState, action: PayloadAction<any>) => {
      state.automaticallyApproveWhenDue = action.payload.automaticallyApproveWhenDue;
    },
    // Reducer to set deduction notification
    setDeductionNotification: (state: formDataState, action: PayloadAction<any>) => {
      state.deductionNotification = action.payload.deductionNotification;
    },
    // Reducer to set who to pay
    setWhoToPay: (state: formDataState, action: PayloadAction<any>) => {
      state.whoToPay = action.payload.whoToPay;
    },
    // Reducer to set selected departments
    setDepartments: (state: formDataState, action: PayloadAction<any>) => {
      state.selectedDepartments = action.payload.selectedDepartments;
    },
    // Reducer to set selected employees
    setEmployeesOnPayroll: (state: formDataState, action: PayloadAction<any>) => {
      state.employees_list = action.payload.employees_list;
    },
    // Reducer to set approvers list
    setApprovers: (state: formDataState, action: PayloadAction<any>) => {
      state.approvers_list = action.payload.approvers_list;
    },
    setNoEmployeesCanBeAddedToPayroll: (state: formDataState, action: PayloadAction<any>) => {
      state.noEmployeesCanBeAddedToPayroll = action.payload.noEmployeesCanBeAddedToPayroll;
    },
    setIsLoadingEmployees: (state: formDataState, action: PayloadAction<any>) => {
      state.isLoadingEmployees = action.payload.isLoadingEmployees;
    },
    // Reducer to set reset the form data state
    resetForm: (state: formDataState, action: PayloadAction<any>) => {
      state.payrollName = "";
      state.payrollPayDay = 1;
      state.payrollInitialPaymentDate = "",
        state.howOftenToPayEmployees = {
          type: "",
          label: "",
        };
      state.automaticallyApproveWhenDue = {
        type: "",
        label: "",
      };
      state.deductionNotification = {
        type: "",
        label: "",
      };
      state.whoToPay = {
        type: "",
        label: "",
      };
      state.selectedDepartments = [];
      state.approvers_list = [];
      state.employees_list = [];
      state.noEmployeesCanBeAddedToPayroll = false;
      state.isLoadingEmployees = false;
    },
  },
});

// Exporting action creators and reducer
export const {
  setApprovers,
  setPayrollName,
  setPayrollPayDay,
  setPayrollInitialPaymentDate,
  setHowOftenToPayEmployees,
  setAutomaticallyApproveWhenDue,
  setDeductionNotification,
  setWhoToPay,
  setDepartments,
  setEmployeesOnPayroll,
  setNoEmployeesCanBeAddedToPayroll,
  setIsLoadingEmployees,
  resetForm,
} = payrollFormDataSlice.actions;

export default payrollFormDataSlice.reducer;
