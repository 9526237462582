import { Button, Input, Typography } from "@zigops-material/react";
import { useFundUsingCustomerCodeMutation } from "@/apis/tiltApiSlice";
import { object, number } from 'yup';
import { Form, Formik } from "formik";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/configureStore";
import InfoIcon from "@/components/icons/info";
import ExclamationCircleIcon from "@/components/icons/exclamationCircle";

interface FundUsingCustomerCodeFormValues {
    amount: number;
}

const FundUsingCustomerCode = ({ customerCode }: { customerCode: string }) => {
    const [fundUsingCustomerCode] = useFundUsingCustomerCodeMutation();
    const [fundingSuccess, setFundingSuccess] = useState<boolean>();
    const [fundingError, setFundingError] = useState<boolean>();
    const { TenantId } = useSelector((state: RootState) => state.app_reducer.companyInfo);
    const initialFormValues: FundUsingCustomerCodeFormValues = {
        amount: 0
    }
    const validationSchema = object({
        amount: number()
            .min(5, "Amount must be at least 5.")
            .max(1_000_000, "Amount must not be more than 1000000.")
    });

    const onSubmit = async (formValues: FundUsingCustomerCodeFormValues) => {
        await fundUsingCustomerCode({
            customerCode: customerCode,
            amount: formValues.amount,
            tenantId: TenantId
        }).then((response) => {
            /* If the response doesn't have any data then the response code is 204 (No Content), which
                means the funding was successful.
            */
            // @ts-ignore
            if (!response.data) {
                setFundingSuccess(true);
                setFundingError(false);
            } else {
                setFundingError(true);
                setFundingSuccess(false);
            }
        });
    }

    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={initialFormValues}
            validationSchema={validationSchema}
        >{({
            values,
            isSubmitting,
            touched,
            errors,
            handleChange
        }) => (
            <Form className="flex flex-col gap-4">
                {fundingSuccess &&
                    <div className="rounded-lg bg-green-200 p-2 items-center text-gray-800 dark:text-gray-300 flex mb-5">
                        <InfoIcon color="#424242" width={16} height={16} />
                        <div className="flex justify-between items-center w-full pl-2">
                            <Typography className="text-gray-800">
                                Successfully funded payroll.
                            </Typography>
                        </div>
                    </div>
                }
                {fundingError &&
                    <div className="rounded-lg bg-red-200 p-2 items-center text-gray-800 dark:text-gray-300 flex mb-5">
                        <ExclamationCircleIcon color="#424242" width={16} height={16} />
                        <div className="flex justify-between items-center w-full pl-2">
                            <Typography className="text-gray-800">
                                Failed to fund payroll. Please try again.
                            </Typography>
                        </div>
                    </div>
                }
                <Input
                    name="amount"
                    value={values.amount}
                    className="w-[50%]"
                    type="number"
                    onChange={handleChange}
                    label="Amount"
                    error={Boolean(touched.amount && errors.amount)}
                />
                <div className="text-red-500">{touched.amount && errors.amount}</div>
                <div className="flex justify-end">
                    <Button className="capitalize" type="submit" disabled={isSubmitting}>
                        Fund payroll
                    </Button>
                </div>
            </Form>)
            }
        </Formik>

    );
}

export default FundUsingCustomerCode;