import { Typography } from "@zigops-material/react";
import { useEffect, useState } from "react";
import FundUsingCustomerCode from "./FundUsingCustomerCode";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/configureStore";

const CustomerCodeAndFundingInstructions = ({ customerCode }: { customerCode: string; }) => {
    const ldClient = useLDClient();
    const { TenantId } = useSelector((state: RootState) => state.app_reducer.companyInfo);
    const [isFundingFromUiEnabled, setIsFundingFromUiEnabled] = useState<boolean>(false);


    useEffect(() => {
        ldClient?.identify({
            kind: 'company',
            key: TenantId
        }, null, (error, flags) => {
            setIsFundingFromUiEnabled(flags['fund-using-customer-code-from-ui']);
        });
    }, [ldClient, TenantId]);

    const depositInstructions = [
        {
            title: "Fund via Electronic Funds Transfer (EFT)",
            content: {
                1: "Log in to your bank’s online banking platform.",
                2: "Select the option to transfer/send funds.",
                3: "Select transfer to other institutions or Mobile Money.",
                4: "Select Zoona.",
                5: "Enter the amount you wish to deposit.",
                6: `Enter ${customerCode} as the reference.`,
                7: "Proceed to complete the transaction."
            }
        },
        {
            title: "Deposit Cash at a Zoona Agent",
            content: {
                1: "Visit any Zoona agent.",
                2: "Inform the agent that you would like to deposit funds using a customer code.",
                3: `Provide the agent with the customer code ${customerCode}.`,
                4: "Provide the agent with the amount you wish to deposit.",
                5: "Provide the agent with your phone number.",
                6: "The agent will process the transaction and provide you with a receipt."
            }
        },
        {
            title: "List of Zoona Agents",
            content: {
                1: "Absa",
                2: "Airtel",
                3: "Atlas Mara",
                4: "Ecobank",
                5: "FNB (First National Bank)",
                6: "Kazang",
                7: "MTN",
                8: "Stanbic Bank",
                9: "Zanaco",
                10: "Zamtel",
                11: "ZICB",
                12: "Zoona"
            }
        }
    ]
    return (
        <div className="flex flex-col w-[100%] h-[70vh] gap-4">
            <div className="h-[100%] w-[100%] gap-4 p-5 [@media(min-width:461px)]:px-10 flex flex-col overflow-y-scroll animate-fade-up rounded-md border border-gray-300 bg-white dark:border-gray-750 dark:bg-gray-850">
                <Typography className="text-2xl mt-10 text-gray-500">Your customer code: {customerCode}</Typography>
                <div className="divider" />
                <div className="w-[100%] h-[100%] flex flex-col gap-4 pb-4">
                    <Typography>
                        How to deposit funds for your payroll:
                    </Typography>
                    <Accordion items={depositInstructions} />
                    {isFundingFromUiEnabled &&
                        <div className="flex flex-col gap-4">
                            <Typography>
                                Fund payroll
                            </Typography>
                            <FundUsingCustomerCode customerCode={customerCode} />
                        </div>}
                </div>
            </div>
        </div>
    );
};

const Accordion = ({ items }) => {
    const [openIndex, setOpenIndex] = useState(null);

    const toggleAccordion = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <div className="w-full">
            <div className="flex h-full w-full flex-col overflow-auto">
                {items.map((item, index) => (
                    <div key={index} className="mb-4 overflow-auto font-bold">
                        <button
                            className="flex w-full justify-between bg-[#ffffff] dark:bg-gray-850 dark:border-gray-750 rounded-md border px-4 py-2 text-left"
                            onClick={() => toggleAccordion(index)}
                        >
                            <span>{item.title}</span>
                            <span
                                className={`p-1 text-blue-500 ${openIndex === index ? "text-blue-500" : "text-blue-500"
                                    }`}
                            >
                                {openIndex === index ? "-" : "+"}
                            </span>
                        </button>
                        {openIndex === index && (
                            <div className="flex flex-col gap-3 overflow-auto bg-white dark:bg-gray-850 dark:border-gray-750 p-4">
                                {Object.entries(item.content).map(([key, value]) => (
                                    <div className="border-m3-sys-light-inverse-on-surface relative flex shrink-0 flex-row items-start justify-between overflow-hidden bg-[#ffffff] rounded-md border dark:bg-gray-850 dark:border-gray-750 pl-6 pr-6 pt-3">
                                        {item.title === "List of Zoona Agents" ? null :
                                            <div className="text-m3-sys-light-inverse-surface font-m3-body-large-font-family text-m3-body-large-font-size leading-m3-body-large-line-height font-m3-body-large-font-weight relative text-left">
                                                Step {key}:
                                            </div>
                                        }
                                        <div className="text-m3-sys-light-outline font-m3-body-large-font-family text-m3-body-large-font-size leading-m3-body-large-line-height font-m3-body-large-font-weight relative text-left">
                                            {value.toString()}
                                            {""}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default CustomerCodeAndFundingInstructions;