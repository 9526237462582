import { CompanyInterface } from "@/constants/companysetup.constants";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: CompanyInterface = {
  type: "",
  DisplayName: "",
  CompanyId: "",
  TenantId: "",
  Tier: "",
  roles: [],
  TaxId: "",
  RegistrationNumber: "",
  Country: "",
  isCompanySet: false,
  isActive: false,
  rememberCompany: false,
  City: "",
  Province: "",
  CompanyEmail: "",
  PhoneNumber: "",
  PhysicalAddress: "",
  PostalAddress: "",
  PayrollFundingReferenceCode: ""
};

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setCompany: (state: CompanyInterface, action: PayloadAction<any>) => {
      state.type = action.payload.type;
      state.CompanyId = action.payload.CompanyId;
      state.TenantId = action.payload.TenantId;
      state.Country = action.payload.Country;
      state.DisplayName = action.payload.DisplayName;
      state.RegistrationNumber = action.payload.RegistrationNumber;
      state.TaxId = action.payload.TaxId;
      state.Tier = action.payload.Tier;
      state.roles = action.payload.roles;
      state.rememberCompany = action.payload.rememberCompany;
      state.isActive = action.payload.isActive;
      state.isCompanySet = true;
      state.PayrollFundingReferenceCode = action.payload.PayrollFundingReferenceCode;
    },
    removeCompany: (state: CompanyInterface, action: PayloadAction<any>) => {
      state.type = "";
      state.CompanyId = "";
      state.Country = "";
      state.DisplayName = "";
      state.RegistrationNumber = "";
      state.TaxId = "";
      state.Tier = "";
      state.roles = [];
      state.isActive = false;
      state.rememberCompany = false;
      state.isCompanySet = false;
      state.PayrollFundingReferenceCode = "";
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCompany, removeCompany } = companySlice.actions;

export default companySlice.reducer;
