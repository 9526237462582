import { Employee } from "@/apis/models/employee.model";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ManageEmployeeInfo {
    isUpdatingCompensationInfo: boolean;
    isUpdatingPersonalInfo: boolean;
    isSuccessUpdatingCompensationInfo: boolean;
    employeeToManage: Employee;
}

export const employeeInfoManagement = createSlice({
    name: "manageEmployeeInfo",
    initialState: {
        isUpdatingCompensationInfo: false,
        isUpdatingPersonalInfo: false,
        isSuccessUpdatingCompensationInfo: false,
        employeeToManage: null
    },
    reducers: {
        setIsUpdatingCompensationInfo: (state: ManageEmployeeInfo, action: PayloadAction<boolean>) => {
            state.isUpdatingCompensationInfo = action.payload;
        },
        setIsUpdatingPersonalInfo: (state: ManageEmployeeInfo, action: PayloadAction<boolean>) => {
            state.isUpdatingPersonalInfo = action.payload;
        },
        setIsSuccessUpdatingCompensationInfo: (state: ManageEmployeeInfo, action: PayloadAction<boolean>) => {
            state.isSuccessUpdatingCompensationInfo = action.payload;
        },
        setEmployeeToManage: (state: ManageEmployeeInfo, action: PayloadAction<Employee>) => {
            state.employeeToManage = action.payload;
        }
    }
})

export const {
    setIsUpdatingCompensationInfo,
    setIsUpdatingPersonalInfo,
    setEmployeeToManage,
    setIsSuccessUpdatingCompensationInfo
} = employeeInfoManagement.actions;
export default employeeInfoManagement.reducer;
