import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Navbar,
  MobileNav,
  Typography,
  Button,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Avatar,
  Card,
  IconButton,
  Checkbox,
  Badge,
} from "@zigops-material/react";
import {
  UsersIcon,
  IdentificationIcon,
  Square3Stack3DIcon,
  ChevronDownIcon,
  RocketLaunchIcon,
  Bars2Icon,
  BellIcon,
  HomeIcon,
  Cog8ToothIcon,
  ArrowRightOnRectangleIcon,
} from "@heroicons/react/24/outline";
import { logout } from "@/utils/authService";
import { useMsal } from "@azure/msal-react";
import { userLoggedOut } from "@/redux/features/auth/user";
import { useDispatch, useSelector } from "react-redux";
import { removeCompany } from "@/redux/features/company";
import {
  getCompanyInfo,
  removeCompanyInfo,
  saveCompany,
} from "@/redux/actions/companyActions";
import {
  COMPANY_INFO,
  CompanyTempInterface,
} from "@/constants/companysetup.constants";
import { RootState } from "@/redux/configureStore";
import { useRouter } from "next/router";
import useTokenExpiryNotifier from "@/hooks/useTokenExpiryNotifier";

interface Props {
  setMobileNav: Dispatch<SetStateAction<boolean>>;
  changeMobileNavToggleState: Dispatch<SetStateAction<boolean>>;
}

interface menuProps {
  label: string;
  type: string;
  description: string | undefined;
  icon: any;
}

function ProfileMenu() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const closeMenu = () => setIsMenuOpen(false);

  const { firstName, lastName, email } = useSelector(
    (state: RootState) => state.app_reducer.activeEmployee,
  );

  const { instance, accounts } = useMsal();
  const dispatch = useDispatch();

  const [profileMenuItems, setProfileMenuItems] = useState<menuProps[]>([]);

  useEffect(() => {
    setProfileMenuItems([
      {
        label: `${firstName} ${lastName}`,
        type: "info",
        description: email,
        icon: Cog8ToothIcon,
      },
      {
        label: "Settings",
        type: "menu",
        description: "",
        icon: Cog8ToothIcon,
      },
      {
        label: "Sign Out",
        type: "sign_out",
        description: "",
        icon: ArrowRightOnRectangleIcon,
      },
    ]);
  }, []);

  const handleSignOut = async () => {
    if (accounts[0]) await logout(instance, accounts[0].homeAccountId);
    dispatch(userLoggedOut({}));
    dispatch(removeCompany({}));
    removeCompanyInfo(COMPANY_INFO, "all");
  };

  const handleMenuClick = (type: string) => {
    switch (type) {
      case "info":
        closeMenu();
        break;
      case "":
        closeMenu();
        break;
      case "sign_out":
        closeMenu();
        handleSignOut();
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Menu open={isMenuOpen} handler={setIsMenuOpen} placement="bottom-end">
        <MenuHandler>
          <Button
            variant="text"
            color="blue-gray"
            className="flex items-center gap-1 rounded-full py-0.5 pl-0.5 pr-2 lg:ml-auto"
          >
            {/* User avatar */}
            <Avatar
              variant="circular"
              size="sm"
              alt={firstName}
              className="border border-blue-500 p-0.5"
              src="/img/20ww.jpg"
              onError={(e) => {
                e.currentTarget.src = "/img/default_avatar.png";
              }}
            />
            {/* Dropdown icon */}
            <ChevronDownIcon
              strokeWidth={2.5}
              className={`h-3 w-3 transition-transform ${isMenuOpen ? "rotate-180" : ""}`}
            />
          </Button>
        </MenuHandler>

        {/* Menu items */}
        <MenuList className="w-70 p-1">
          {profileMenuItems.map(({ label, icon, type, description }, key) => {
            const isLastItem = key === profileMenuItems.length - 1;
            return type === "info" ? (
              // Special case for 'info' type menu item
              <div className="mb-2 border-none p-3">
                <div className="mb-3 pr-5">
                  <h5 className="font-medium">{label}</h5>
                  <div className="text-gray-400">{description}</div>
                </div>
                <div>
                  <Button fullWidth className="bg-amber-800">
                    Switch Profile
                  </Button>
                </div>
              </div>
            ) : (
              // Normal menu item
              <MenuItem
                key={label}
                onClick={() => handleMenuClick(type)}
                className={`flex items-center gap-2 rounded ${isLastItem ? "hover:bg-red-500/10 focus:bg-red-500/10 active:bg-red-500/10" : ""
                  }`}
              >
                {React.createElement(icon, {
                  className: `h-4 w-4 ${isLastItem ? "text-red-500" : ""}`,
                  strokeWidth: 2,
                })}
                <Typography
                  as="span"
                  variant="small"
                  className="font-normal"
                  color={isLastItem ? "red" : "inherit"}
                >
                  {label}
                </Typography>
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    </>
  );
}

// Menu items for the navigation list
const navListMenuItems = [
  {
    title: "@zigops-material/html",
    description: "Learn how to use @zigops-material/html, packed with rich components and widgets.",
  },
  {
    title: "@zigops-material/react",
    description: "Learn how to use @zigops-material/react, packed with rich components for React.",
  },
  {
    title: "Material Tailwind PRO",
    description: "A complete set of UI Elements for building faster websites in less time.",
  },
];

// Navigation list items
const navListItems = [
  {
    label: "Home",
    icon: HomeIcon,
  },
  {
    label: "Employees",
    icon: UsersIcon,
  },
  {
    label: "Payroll",
    icon: IdentificationIcon,
  },
];

// NavList component for rendering navigation list
function NavList() {
  return (
    <ul className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center">

      {/* Rendering navigation list items */}
      {navListItems.map(({ label, icon }) => (
        <Typography
          key={label}
          as="a"
          href="#"
          variant="small"
          color="blue-gray"
          className="font-normal"
        >
          <MenuItem className="flex items-center gap-2 lg:rounded-full">
            {React.createElement(icon, { className: "h-[18px] w-[18px]" })} {label}
          </MenuItem>
        </Typography>
      ))}
    </ul>
  );
}

export default function Header({ setMobileNav, changeMobileNavToggleState }: Props) {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [CompanyList, setCompanyList] = useState<CompanyTempInterface[]>([]);
  const [isSettingCompany, setIsSettingCompany] = useState(false);

  const { accessToken } = useSelector((state: RootState) => state.app_reducer.user);
  const { CompanyId, DisplayName } = useSelector(
    (state: RootState) => state.app_reducer.companyInfo,
  );
  const { role } = useSelector((state: RootState) => state.app_reducer.activeEmployee);

  const { unreadCount } = useSelector((state: RootState) => state.app_reducer.notificationState);

  // msal hook
  const { instance, accounts } = useMsal();

  // Next.js router
  const router = useRouter();

  // Redux dispatch function
  const dispatch = useDispatch();

  // Function to toggle the visibility of the navigation menu
  const toggleIsNavOpen = () => {
    setIsNavOpen((cur) => !cur);
    changeMobileNavToggleState((cur) => !cur);
  };

  // Function to handle fetching company information
  const handleCompanyInfo = async () => {
    const SavedCompanies = await getCompanyInfo(COMPANY_INFO);
    if (SavedCompanies !== "undefined")
      if (JSON.parse(SavedCompanies))
        // @ts-ignore
        setCompanyList(JSON.parse(SavedCompanies));
  };

  // Function to handle other company information
  const handleAddNewCompany = async () => {
    router.push("/admin/add-company");
  };

  // Function to handle changing the active company
  const handleChangeActiveCompany = async (companyId: string) => {
    const index = CompanyList.findIndex((item: any) => item.CompanyId === companyId);
    const savedCompany = {
      type: CompanyList[index].type,
      DisplayName: CompanyList[index].displayName,
      CompanyId: companyId,
      TenantId: CompanyList[index].tenantId,
      Tier: CompanyList[index].Tier,
      TaxId: CompanyList[index].TaxId,
      RegistrationNumber: CompanyList[index].registrationNumber,
      Country: CompanyList[index].country,
      roles: CompanyList[index].roles,
      isCompanySet: true,
      isActive: true,
      rememberCompany: CompanyList[index].rememberCompany,
      City: CompanyList[index].city,
      Province: CompanyList[index].province,
      CompanyEmail: CompanyList[index].companyEmail,
      PhoneNumber: CompanyList[index].phoneNumber,
      PhysicalAddress: CompanyList[index].physicalAddress,
      PostalAddress: CompanyList[index].postalAddress,
      PayrollFundingReferenceCode: CompanyList[index].payrollFundingReferenceCode,
    };

    await saveCompany(COMPANY_INFO, savedCompany, dispatch, () => {
      setIsSettingCompany(true);
    });
  };

  // useEffect to handle initial setup and window resize event
  useEffect(() => {
    handleCompanyInfo();

    window.addEventListener("resize", () => {
      window.innerWidth >= 960 && setIsNavOpen(false);
      window.innerWidth >= 960 && setMobileNav(false);
    });
  }, []);

  const handleNavigateToNotifications = () => {
    if (role === "admin") {
      router.push(`/household/notifications`);
    } else {
      router.push(`/employee/notifications`);
    }
  };

  /**
   * This hook checks if the B2C token is expired.
   */
  useTokenExpiryNotifier(
    { bearerToken: accessToken, accounts, instance },
    logout,
    dispatch,
    removeCompanyInfo,
    COMPANY_INFO,
  );

  // JSX for rendering the Header component
  return (
    <Navbar className="inset-0 z-10 h-max max-w-full rounded-none border-l-0 border-gray-350 bg-white p-2 shadow-none dark:border-gray-750 dark:bg-gray-850 dark:text-blue-gray-200">
      <div className="flex items-center justify-between lg:justify-end text-blue-gray-900 dark:text-blue-gray-200">
        {/* Hamburger menu icon for small screens */}
        <IconButton
          size="md"
          color="blue-gray"
          variant="text"
          onClick={toggleIsNavOpen}
          className="h-5 w-5 mr-2 lg:hidden"
        >
          <Bars2Icon className="h-6 w-6 dark:text-blue-gray-200" />
        </IconButton>

        {/* Right side - Notification icon, ProfileMenu, Company switch menu */}
        <div className="flex items-center space-x-3">
          {/* Notification icon */}
          {unreadCount > 0 ? (
            <Badge className="mt-2" content={unreadCount}>
              <IconButton onClick={handleNavigateToNotifications} color="gray" variant="text">
                <BellIcon
                  strokeWidth={1.5}
                  className="h-5 w-5 text-gray-600 transition-transform dark:text-blue-gray-200"
                />
              </IconButton>
            </Badge>
          ) : (
            <IconButton onClick={handleNavigateToNotifications} color="gray" variant="text">
              <BellIcon
                strokeWidth={1.5}
                className="h-5 w-5 text-gray-600 transition-transform dark:text-blue-gray-200"
              />
            </IconButton>
          )}

          {/* Rendering user profile menu */}
          <ProfileMenu />

          {/* Company switch menu */}
          <Menu
            dismiss={{
              itemPress: false,
            }}
          >
            <MenuHandler>
              <Button variant="text" size="sm" className="flex capitalize dark:text-gray-100">
                <div>{DisplayName}</div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="ml-2 h-4 w-4 dark:text-gray-500"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </div>
              </Button>
            </MenuHandler>
            <MenuList>
              {CompanyList.map((company) => (
                <MenuItem
                  key={company.companyId}
                  onClick={() => {
                    handleChangeActiveCompany(company.displayName);
                  }}
                  className="p-0"
                >
                  <label htmlFor={`company-${company.companyId}`} className="flex cursor-pointer items-center gap-2 p-2">
                    <Checkbox
                      ripple={false}
                      checked={company.companyId === CompanyId}
                      id={`company-${company.companyId}`}
                      containerProps={{ className: "p-0" }}
                      className="hover:before:content-none"
                    />
                    {company.displayName}
                  </label>
                </MenuItem>
              ))}
              <hr className="my-3 dark:border-gray-750" />
              <MenuItem onClick={handleAddNewCompany} className="p-2">
                Add a new company
              </MenuItem>
            </MenuList>
          </Menu>
        </div>
      </div>
      <MobileNav open={isNavOpen} className="overflow-scroll">
        <NavList />
      </MobileNav>
    </Navbar>

  );
}
