import React, { useEffect, useState } from "react";
import { Stepper, Step, Button, Typography } from "@zigops-material/react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { toast, ToastContainer } from "react-toastify";
import CustomerPhoneNumber from "./CustomerPhoneNumber";
import CustomerName from "./CustomerName";
import { useGenerateCustomerCodeMutation } from "@/apis/paymentProcessingApiSlice";
import { RootState } from "@/redux/configureStore";
import { CustomerCodeGenerationRequest } from "@/types/Tilt";
import { FULL_NAME_REGEX, ZAMBIAN_PHONE_NUMBER_REGEXS } from "@/constants/regularExpressions";
import { clearFormData, setCustomerCode, setCustomerNameError, setCustomerPhoneNumberError } from "@/redux/features/payrollFunding/customer-code-funding-method-form-data";
import { HTTP_STATUS_CODE_200 } from "@/constants/common.constants";
import CustomerCodeAndFundingInstructions from "./CustomerCodeAndFundingInstructions";

interface stepperProps {
    parentIndex: number;
    childIndex: number;
    parentId: string;
    childId: string;
    isError: boolean;
    errorMsg: string;
}

const CustomerCodeFundingMethod = () => {
    const [generateCustomerCode, { isLoading: isGeneratingCustomerCode }] = useGenerateCustomerCodeMutation();
    const {
        customerPhoneNumber,
        customerName,
        customerCode
    } = useSelector((state: RootState) => state.app_reducer.customerCodeFundingMethodFormData);
    const company = useSelector((state: RootState) => state.app_reducer.companyInfo);
    const dispatch = useDispatch();

    const [activeStep, setActiveStep] = useState(0);
    const [activeChildStep, setActiveChildStep] = useState<stepperProps>({
        parentIndex: 0,
        childIndex: 0,
        parentId: "",
        childId: "",
        isError: false,
        errorMsg: "",
    });
    const [isLastStep, setIsLastStep] = useState(false);
    const [isFirstStep, setIsFirstStep] = useState(false);
    const views = [
        {
            id: "PhoneNumber",
            children: [
                { childId: "PhoneNumber", view: <CustomerPhoneNumber /> },
            ],
        },
        {
            id: "Name",
            children: [
                { childId: "Name", view: <CustomerName /> },
            ]
        }
    ];

    // Function to handle moving to the next step
    const handleNextStep = () => {
        // handle validation here
        let isValid = true; // Assume the form is valid by default

        if (activeStep === 0) {
            if (customerPhoneNumber === "+260") {
                dispatch(setCustomerPhoneNumberError('Phone number is required.'));
                isValid = false;
                return;
            }

            if (!(customerPhoneNumber.match(ZAMBIAN_PHONE_NUMBER_REGEXS.MTN) ||
                customerPhoneNumber.match(ZAMBIAN_PHONE_NUMBER_REGEXS.Airtel) ||
                customerPhoneNumber.match(ZAMBIAN_PHONE_NUMBER_REGEXS.Zamtel))) {
                dispatch(setCustomerPhoneNumberError('Invalid phone number.'));
                isValid = false;
            }
        }

        if (isValid) {
            // Proceed to the next step only if isValid is true
            const currentParentStep = activeStep;
            const currentChildStep = activeChildStep.childIndex;

            if (currentChildStep < views[currentParentStep].children.length - 1) {
                setActiveChildStep((prevState) => ({
                    ...prevState,
                    childIndex: prevState.childIndex + 1,
                }));
            } else {
                if (currentParentStep < views.length - 1) {
                    setActiveStep(currentParentStep + 1);
                    setActiveChildStep({
                        parentIndex: currentParentStep + 1,
                        childIndex: 0,
                        parentId: "",
                        childId: "",
                        isError: false,
                        errorMsg: "",
                    });
                }
            }

            setIsFirstStep(false);

            setIsLastStep(
                currentParentStep === views.length - 2 &&
                currentChildStep === views[currentParentStep].children.length - 1,
            );
        }
    };

    // Function to handle moving back to the previous step
    const handleBack = () => {
        if (activeChildStep.childIndex > 0) {
            setActiveChildStep((prevState) => ({
                ...prevState,
                childIndex: prevState.childIndex - 1,
            })); // Updating active child step index
        } else {
            if (activeStep > 0) {
                setActiveStep(activeStep - 1); // Moving to the previous parent step
                const newParentIndex = activeStep - 1;
                setActiveChildStep({
                    parentIndex: newParentIndex,
                    childIndex: views[newParentIndex].children.length - 1,
                    parentId: "",
                    childId: "",
                    isError: false,
                    errorMsg: "",
                }); // Resetting active child step
            }
        }

        setIsLastStep(false); // Reset isLastStep
        setIsFirstStep(activeStep === 0 && activeChildStep.childIndex === 1); // Checking if it's the first step
    };

    useEffect(() => {
        // Check if the current activeStep is 0 and the childIndex has reached the end
        if (activeStep === 0 && activeChildStep.childIndex === views[activeStep].children.length) {
            // Set activeStep to 1
            setActiveStep(activeStep + 1);
        }
    }, [activeStep, activeChildStep.childIndex, views]);

    // Function to handle form submission
    const onSubmit = async () => {
        if (!customerName) {
            dispatch(setCustomerNameError('Name is required.'));
            return;
        }

        if (!customerName.match(FULL_NAME_REGEX)) {
            dispatch(setCustomerNameError("Invalid name. The first name and/or middle name and last name must be separated by a space. " +
                "The name can contain only alphabetic characters, hyphens and apostrophes."));
            return;
        }

        const customerCodeRequest: CustomerCodeGenerationRequest = {
            tenantId: company.TenantId,
            customerMobileNumber: customerPhoneNumber,
            customerName: customerName,
        }

        try {
            await toast.promise(async () => {
                const response = await generateCustomerCode(customerCodeRequest).unwrap();
                // @ts-ignore
                if (response.status === HTTP_STATUS_CODE_200) {
                    setTimeout(() => {
                        // @ts-ignore
                        dispatch(setCustomerCode(response.data.customerCode));
                        dispatch(clearFormData());
                    }, 1000);
                }
            },
                {
                    pending: "Generating customer code...",
                    success: "Customer code generated successfully",
                    error: "Failed to generate customer code. Please try again.",
                });
        }
        catch (error) {
            // ignore the error since the toast will display an error message.
        }

    }

    return (
        <div className="ml-4">
            <>
                <div className="w-full animate-fade-left p-5 animate-once border-b border-b-gray-400 pb-4 dark:border-b-gray-750">
                    Payroll Funding
                </div>
                {customerCode || company.PayrollFundingReferenceCode ? <CustomerCodeAndFundingInstructions customerCode={customerCode || company.PayrollFundingReferenceCode} /> :
                    <div className="h-[70vh] w-[100%] flex flex-col gap-4">
                        {/* Stepper component to display progress */}
                        <Stepper activeStep={activeStep} className="w-[100%] pl-2 pr-11 py-4">
                            {/* Step 1 */}
                            <Step onClick={() => setActiveStep(0)}>
                                <div>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        className="h-6 w-6"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M5.828 13.243a.5.5 0 0 0-.117.207l-2 5a.5.5 0 0 0 .665.664l5-2a.5.5 0 0 0 .206-.116l9.5-9.5a.5.5 0 0 0 0-.707l-2.586-2.586a.5.5 0 0 0-.707 0l-9.5 9.5zM4.414 14.657l7.086-7.086 1.586 1.586-7.086 7.086-1.586-1.586zm-1.172 4.172l-.5 1.5 1.5-.5 9.5-9.5-1-1L12.828 6.414l-1.414 1.414 1 1L6.828 17.414z"
                                            clip-rule="evenodd"
                                        />
                                    </svg>
                                </div>
                                <div
                                    className={classNames(
                                        "absolute w-max pl-2 pr-2 text-center dark:bg-gray-950 dark:border-gray-750 bg-[#ffffff]",
                                        activeStep === 0 ? "left-12" : "left-10",
                                    )}
                                >
                                    <Typography
                                        className={classNames(
                                            activeStep === 0 ? "text-blue-500" : "text-gray-800 dark:text-gray-150",
                                        )}
                                    >
                                        Phone Number
                                    </Typography>
                                </div>
                            </Step>
                            {/* Step 2 */}
                            <Step onClick={() => setActiveStep(1)}>
                                <div>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        className="h-6 w-6"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M5.828 13.243a.5.5 0 0 0-.117.207l-2 5a.5.5 0 0 0 .665.664l5-2a.5.5 0 0 0 .206-.116l9.5-9.5a.5.5 0 0 0 0-.707l-2.586-2.586a.5.5 0 0 0-.707 0l-9.5 9.5zM4.414 14.657l7.086-7.086 1.586 1.586-7.086 7.086-1.586-1.586zm-1.172 4.172l-.5 1.5 1.5-.5 9.5-9.5-1-1L12.828 6.414l-1.414 1.414 1 1L6.828 17.414z"
                                            clip-rule="evenodd"
                                        />
                                    </svg>
                                </div>
                                <div
                                    className={classNames(
                                        "absolute w-max bg-gray-200 pl-2 pr-2 text-center dark:bg-gray-950 bg-[#ffffff]",
                                        activeStep === 2 ? "left-12" : "left-10",
                                    )}
                                >
                                    <Typography
                                        className={classNames(
                                            activeStep === 1 ? "text-blue-500" : "text-gray-800 dark:text-gray-150",
                                        )}
                                    >
                                        Name
                                    </Typography>
                                </div>
                            </Step>
                        </Stepper>
                        {/* Displaying the current step view */}
                        {views[activeStep].children[activeChildStep.childIndex].view}
                        <div className="flex gap-4">
                            {/* Back button */}
                            <Button variant="text" onClick={handleBack} disabled={isFirstStep || isGeneratingCustomerCode}>
                                Back
                            </Button>
                            {isLastStep ? (
                                <div>
                                    <Button disabled={isGeneratingCustomerCode} onClick={() => onSubmit()}>
                                        Complete
                                    </Button>
                                </div>
                            ) : (
                                <Button
                                    onClick={handleNextStep}
                                    disabled={isLastStep}
                                >
                                    Next
                                </Button>
                            )}
                        </div>
                    </div>}

                <ToastContainer
                    theme="dark"
                    position="bottom-center"
                    autoClose={7000}
                    hideProgressBar={true}
                />
            </>
        </div>
    );
};

export default CustomerCodeFundingMethod;
