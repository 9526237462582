import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { server } from "@/config/server";
import getHeaders from "./headers";
import { Account } from "@/types/Accounting";

export const accountingApiSlice = createApi({
    reducerPath: "accountingApi",
    baseQuery: fetchBaseQuery({
        baseUrl: server,
        prepareHeaders: getHeaders,
    }),
    tagTypes: ["Accounting"],
    endpoints: (builder) => ({
        getCompanyAccount: builder.query<Account, { tenantId: string, accountId: string }>({
            query: ({ tenantId, accountId }) => `/companies/${tenantId}/accounts/${accountId}`,
            providesTags: (result, error, { tenantId, accountId }) => [{ type: "Accounting", id: `companies/${tenantId}/accounts/${accountId}` }],
        }),
    }),
});

export const { useGetCompanyAccountQuery } = accountingApiSlice;
