// Importing necessary modules and types
import { createSlice, PayloadAction } from "@reduxjs/toolkit"; // Importing createSlice and PayloadAction from Redux Toolkit

interface loaderProps {
    percentage: number;
}

// Initialize the initial state of the form data
const initialState: loaderProps = {
    percentage: 0,
};

export const FirstImpressionLoaderReducer = createSlice({
  name: "first-impression-loader",
  initialState,
  reducers: {
    setLoaderPercentage: (state: loaderProps, action: PayloadAction<any>) => {
      state.percentage = action.payload.percentage;
    },
  },
});

// Exporting action creators and reducer
export const { setLoaderPercentage } = FirstImpressionLoaderReducer.actions;

export default FirstImpressionLoaderReducer.reducer;
