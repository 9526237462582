import React, { useEffect, useState } from "react";
import {
  Button,
  Option,
  Select,
  Typography,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Avatar,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Spinner,
} from "@zigops-material/react";
import Head from "next/head";
import { useRouter } from "next/router";
import toast, { Toaster } from "react-hot-toast";
import { RootState } from "@/redux/configureStore";
import { useWindowSize } from "rooks";
import { useDispatch, useSelector } from "react-redux";
import useStorage from "@/hooks/useStorage";
import { removeCompany, setCompany } from "@/redux/features/company";
import { useGetUserTenantsQuery } from "@/apis/OnBoardingSlice";
import { userLoggedOut } from "@/redux/features/auth/user";
import { logout } from "@/utils/authService";
import { useMsal } from "@azure/msal-react";
import { getCompanyInfo, removeCompanyInfo, saveCompany } from "@/redux/actions/companyActions";
import {
  COMPANY_INFO,
  CompanyInterface,
  CompanyTempInterface,
} from "@/constants/companysetup.constants";
import { TOKEN_EXPIRED_MESSAGE } from "@/constants/common.constants";
import { setTenantId } from "@/redux/features/headerSlice";
import { setActiveUser } from "@/redux/features/user/activeUser";
import { useLazyGetEmployeeByIdQuery } from "@/apis/EmployeesApiSlice";
import { useLazyGetCompanyQuery } from "@/apis/companyApiSlice";
import { useCreateDomesticPayrollMutation } from "@/apis/payrollApiSlice";
import { setActivePayroll } from "@/redux/features/activePayroll";
import { setPayrollGroups } from "@/redux/features/payroll/payroll-groups";
import { HOUSEHOLD_PAYROLL_INITIALIZATION_FAILED_MESSAGE } from "@/constants/payroll";
import classNames from "classnames";
import { useLazyGetTenantAccessContextQuery } from "@/apis/subscriptionApiSlice";
import { setHasSubscription } from "@/redux/features/company/subscription";

type Props = {};

const SelectCompany: React.FC<Props> = () => {
  const year = new Date().getFullYear();
  const router = useRouter();

  // Hooks
  const { getItem } = useStorage();
  const { outerHeight } = useWindowSize();
  const { idTokenClaims } = useSelector((state: RootState) => state.app_reducer.user);
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();

  // States
  const [companyId, setCompanyId] = useState<string>("");
  const [companyInformation, setCompanyInformation] = useState<CompanyTempInterface>();
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
  const [rememberCompany, setRememberCompany] = useState<boolean>(true);
  const [Loading, setLoading] = useState<boolean>(true);

  const [open, setOpen] = useState<boolean>(false);
  const [isSettingCompany, setIsSettingCompany] = useState(false);
  const [isAllSet, setIsAllSet] = useState(false);
  const [isErrorOpen, setIsErrorOpen] = useState(false);
  const [isRefreshed, setIsRefreshed] = useState(false);
  const [theme, setTheme] = useState("");
  const [TenantList, setTenantList] = useState<[]>([]);
  const [payrollInit, setPayrollInit] = useState<boolean>(false);
  const [progressStatus, setProgressStatus] = useState<string>("Fetching available companies...");
  const [tokenExpiredText, setTokenExpiredText] = useState<string>("");
  const [progressFailed, setProgressFailed] = useState<boolean>(false);

  // Queries and Mutations
  const {
    data: companyList,
    // the naming starting with (is) represents a boolean value returned from the response
    isLoading: isTenantsLoading,
    isError: isTenantsError,
    error: userTenantsError,
  } = useGetUserTenantsQuery(idTokenClaims.sub);

  const [getTenantAccessContext] = useLazyGetTenantAccessContextQuery();

  const [employeeId, employee] = useLazyGetEmployeeByIdQuery();
  const [getCompany, company] = useLazyGetCompanyQuery();
  const [houseHoldPayrollMutation] = useCreateDomesticPayrollMutation();

  // Function to handle user tenant selection
  const handleTenantSelection = async (tenantId: string) => {
    setCompanyId(tenantId);
    setIsFetchingData(true);

    const companyData = await getCompany(tenantId);

    try {
      setProgressFailed(false);
      setProgressStatus("Verifying company information...");
      if (companyData) {
        setProgressStatus("Verifying user information...");
        dispatch(setTenantId({ tenantId: companyData.data.tenantId }));
        setCompanyInformation({
          ...companyData.data
        });
        setTimeout(async () => {
          await employeeId(`${companyData.data.tenantId}~${idTokenClaims.sub}`);
        }, 600);
        return;
      }
    } catch (error) {
      // @ts-ignore
      if (companyData.error.status === 401) {
        setProgressFailed(true);
        setProgressStatus(TOKEN_EXPIRED_MESSAGE);

        setTimeout(() => {
          handleSignOut();
        }, 7000);
        return;
      }

      setProgressFailed(true);
      setProgressStatus("Error verifying company information.");
    }
  };

  // Set Company
  const handleSetCompany = async () => {
    if (companyId) {
      if (companyInformation.type === "Household") {
        router.push("/household/first-impression");
      } else {
        if (!isRefreshed) router.push("/admin/FirstImpression");
      }
      setIsFetchingData(true);
      try {
        const index = companyList.findIndex((item: any) => item.tenantId === companyId);
        const savedCompany = {
          type: companyInformation.type,
          DisplayName: companyInformation.displayName,
          CompanyId: companyId,
          TenantId: companyList[index].tenantId,
          Tier: companyInformation.Tier,
          TaxId: companyInformation.TaxId,
          RegistrationNumber: companyInformation.registrationNumber,
          Country: companyInformation.country,
          roles: companyList[index].roles,
          isCompanySet: true,
          isActive: true,
          rememberCompany: rememberCompany,
          City: companyInformation.city,
          Province: companyInformation.province,
          CompanyEmail: companyInformation.companyEmail,
          PhoneNumber: companyInformation.phoneNumber,
          PhysicalAddress: companyInformation.physicalAddress,
          PostalAddress: companyInformation.postalAddress,
          PayrollFundingReferenceCode: companyInformation.payrollFundingReferenceCode
        };

        await saveCompany(COMPANY_INFO, savedCompany, dispatch, () => {
          setIsSettingCompany(true);
        });
        setIsFetchingData(false);
      } catch (error) {
        // Handle error...
        router.push("/logout");
      }
    }
  };

  // Handle Remember Company
  const HandleRememberCompany = (event: boolean) => setRememberCompany(event);

  // Handle
  const handleOpen = () => setOpen(!open);

  // Effects

  const getSavedCompany = async () => {
    const SavedCompanies = await getCompanyInfo(COMPANY_INFO);

    if (SavedCompanies !== undefined && SavedCompanies !== null) {
      // @ts-ignore
      const savedCompanies = JSON.parse(SavedCompanies);

      if (savedCompanies) {
        setIsRefreshed(true);

        if (!isErrorOpen)
          savedCompanies.map((company: CompanyInterface) => {
            if (company.isActive) {
              handleTenantSelection(company.TenantId);
            }
          });
      }
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSavedCompany();
  }, []);

  useEffect(() => {
    setTheme(getItem("theme"));
  }, []);

  // Handle User Tenants
  useEffect(() => {
    if (!isTenantsLoading && !isTenantsError) {
      // Handle user tenants...
      setTenantList(companyList);
      setProgressStatus("");

      if (Array.isArray(companyList)) {
        if (companyList.length !== 0) {
          if (companyList.length === 1) {
            // Handle case when there's exactly one company
            setTimeout(() => {
              handleTenantSelection(companyList[0].tenantId);
            }, 2000);
            setProgressStatus("Auto-selecting the only available company...");
          }
        } else {
          // Handle case when companyList is an empty array
          setProgressStatus("User does not belong to any company.");
        }
      } else {
        // Handle case when companyList is not an array
        setProgressStatus("Invalid company data received.");
      }
    } else {
      if (userTenantsError !== undefined) {
        // @ts-ignore
        if (userTenantsError.status === 401) {
          setTokenExpiredText(TOKEN_EXPIRED_MESSAGE);
          setTimeout(() => {
            handleSignOut();
          }, 1000);
        }
      }
    }
  }, [isTenantsLoading, isTenantsError]);

  // Handle house hold payroll initialization
  /**
   * @param CompanyName Name of the company
   * @param TenantId Id of the tenant
   * @param Callback Callback function to call when once the function is done
   * This function initializes the household payroll service if the payroll is already created it will be created
   */
  const houseHoldPayrollInitialization = async (
    companyName: string,
    tenantId: string,
    createdBy: string,
    callback: Function,
  ) => {
    const payrollTemplate = {
      tenantId,
      name: `${companyName}-payroll`,
      createdBy,
    };
    try {
      setProgressFailed(false);
      setProgressStatus("Initializing payroll");
      const res = await houseHoldPayrollMutation(payrollTemplate);
      setPayrollInit(true);
      callback(res);
    } catch (error) {
      setPayrollInit(false);
      callback(false);
      setProgressFailed(true);
      setProgressStatus("Error initializing payroll");
    }
  };

  const checkBrowserState = () => {
    setProgressFailed(false);
    setProgressStatus("Done");
    setTimeout(() => {
      if (isRefreshed) {
        const compInfo = {
          type: companyInformation.type,
          CompanyId: companyInformation.tenantId,
          TenantId: companyInformation.tenantId,
          Country: companyInformation.country,
          DisplayName: companyInformation.displayName,
          RegistrationNumber: companyInformation.registrationNumber,
          TaxId: companyInformation.TaxId,
          Tier: companyInformation.Tier,
          roles: companyInformation.roles,
          rememberCompany: companyInformation.rememberCompany,
          isActive: companyInformation.isActive,
          isCompanySet: true,
          City: companyInformation.city,
          Province: companyInformation.province,
          CompanyEmail: companyInformation.companyEmail,
          PhoneNumber: companyInformation.phoneNumber,
          PhysicalAddress: companyInformation.physicalAddress,
          PostalAddress: companyInformation.postalAddress,
          PayrollFundingReferenceCode: companyInformation.payrollFundingReferenceCode
        };
        dispatch(setCompany(compInfo));
      } else {
        setIsFetchingData(false);
        setIsSettingCompany(false);
        setIsAllSet(true);
        setProgressStatus("");
      }
    }, 1000);
  };

  useEffect(() => {
    if (companyId) {
      setProgressStatus("Verifying subscription information...");
      getTenantAccessContext(companyId).then((res) => {
        if (res.isSuccess && res.data?.status === "Success") {
          dispatch(setHasSubscription({ hasSubscription: true }));
        }
        else {
          dispatch(setHasSubscription({ hasSubscription: false }));
          setProgressStatus("Failed to verify subscription information.");
        }
      });
    }
  }, [companyId]);

  // Handle Employee Effect
  useEffect(() => {
    if (employee.isSuccess) {
      // Handle household logic here
      if (companyInformation.type === "Household") {
        // Call the house hold initialization
        houseHoldPayrollInitialization(
          companyInformation.displayName,
          companyInformation.tenantId,
          employee.data.employeeId,
          async (response: any) => {
            try {
              dispatch(setPayrollGroups({ payrollGroups: [response.data] }));
              dispatch(setActivePayroll({ payrollId: response.data.payrollId }));
              dispatch(setActiveUser({ employee: employee.data }));

              checkBrowserState();
            } catch (error) {
              toast.error(HOUSEHOLD_PAYROLL_INITIALIZATION_FAILED_MESSAGE, {
                duration: 60000,
                style:
                  theme === "dark"
                    ? {
                      borderRadius: "10px",
                      background: "#333",
                      color: "#fff",
                    }
                    : {},
              });
              setProgressFailed(true);
              setProgressStatus("Error initializing payroll");
            }
          },
        );
      } else {
        // Handle cooperate logic here
        dispatch(setActiveUser({ employee: employee.data }));
        handleSetCompany();
        setIsFetchingData(false);
      }
    } else if (employee.isError) {
      // Handle error...
      if (companyId) {
        handleSetCompany();
      }
      setIsFetchingData(false);
      router.push("/auth/subscription");
    }
  }, [employee]);

  // Handle Fetching Data Effect
  useEffect(() => {
    if (isFetchingData) {
      // Handle fetching data...
    }
  }, [isFetchingData]);

  // Handle Employee SignOut
  const handleSignOut = async () => {
    if (accounts[0]) await logout(instance, accounts[0].homeAccountId);
    dispatch(userLoggedOut({}));
    dispatch(removeCompany({}));
    removeCompanyInfo(COMPANY_INFO, "all");
  };

  return (
    <>
      <Toaster position="top-right" />
      <Head>
        <title>Select Company - ZigOps Payroll</title>
      </Head>
      {Loading ? (
        <div className="flex min-h-screen w-full items-center justify-center">
          <div className="items-center justify-center">
            {/* <Image
              className="m-auto"
              src={theme === "dark" ? "/img/Logo-white-text.png" : "/img/main-logo.png"}
              alt="logo"
              width={160
              height={180}
            /> */}
            <Spinner className="m-auto mb-0 h-8 w-8" color="amber" />
            <div className="mt-3 place-content-center text-center">
              <Typography variant="small" className="">
                Loading, please wait...{" "}
              </Typography>
              <Typography className="mt-2 dark:text-gray-500">ZigOps</Typography>
            </div>
            <div className="m-auto min-h-min place-content-center text-center">
              <div>{tokenExpiredText}</div>
            </div>
          </div>
        </div>
      ) : (
        <div className="min-h-screen w-full place-content-center justify-center overflow-hidden">
          <div className="relative z-30 p-2 lg:p-10">
            <div className="z-90 relative mx-auto mb-5 rounded-xl pb-6 pt-6 transition-all sm:w-full md:w-3/5 lg:w-4/5">
              <Card className="m-auto w-96 shadow-xl transition-all dark:bg-gray-850">
                <CardHeader
                  variant="gradient"
                  className="mb-4 grid h-28 place-items-center bg-transparent shadow-none"
                >
                  <div className="overflow-hidden rounded-xl bg-white dark:bg-gray-850">
                    <Avatar
                      src={theme === "dark" ? "/img/Logo-white-text.png" : "/img/main-logo.png"}
                      variant="square"
                      alt="avatar"
                      size="xxl"
                    />
                  </div>
                </CardHeader>
                <CardBody className="flex flex-col gap-4">
                  <div className="text-center">
                    <Typography variant="small" className="text-gray-900 dark:text-gray-400">
                      Select company you want to connect with
                    </Typography>
                  </div>
                  <Select
                    disabled={isTenantsLoading || isFetchingData}
                    label={isTenantsLoading ? "Loading..." : "Select Company"}
                    className="text-gray-800 dark:text-gray-400 disabled:dark:bg-dark-800 "
                    onChange={(tenantId: any) => {
                      setIsFetchingData(true);
                      handleTenantSelection(tenantId);
                    }}
                  >
                    {TenantList &&
                      TenantList.map((company: any) => (
                        <Option
                          key={company.tenantId}
                          value={company.tenantId}
                          className="text-gray-800 dark:text-gray-400"
                        >
                          {company.tenantName}
                        </Option>
                      ))}
                  </Select>
                  <div className="m-2.5">
                    <div className="flex">
                      <input
                        onChange={(e) => {
                          HandleRememberCompany(e.target.checked);
                        }}
                        type="checkbox"
                        id="remember_com"
                        className="toggle toggle-warning toggle-xs"
                        checked={rememberCompany}
                      />
                      <div className="-mt-2 ml-2">
                        <label
                          htmlFor="remember_com"
                          className="text-sm text-gray-800 dark:text-gray-400"
                        >
                          Remember this company
                        </label>
                        <Typography
                          variant="small"
                          className="text-xs font-normal text-gray-500 dark:text-gray-700"
                        >
                          You'll be able to switch between companies without login .
                        </Typography>
                      </div>
                    </div>
                  </div>
                </CardBody>
                <CardFooter className="pt-0">
                  <Button
                    disabled={!isAllSet ? true : isSettingCompany ? true : false}
                    onClick={handleSetCompany}
                    variant="gradient"
                    fullWidth
                    className="flex w-full justify-center"
                  >
                    <div className="flex">
                      {isFetchingData ? (
                        <div>
                          <Spinner className="mr-2 h-4 w-4" />
                        </div>
                      ) : (
                        <div></div>
                      )}

                      <div>Continue with selected</div>
                    </div>
                  </Button>
                  <div
                    className={classNames(
                      "mt-2 w-full text-center text-xs",
                      progressFailed && "text-orange-800",
                    )}
                  >
                    {progressStatus}
                  </div>
                  <Typography variant="small" className="mt-6 flex justify-center">
                    Don't belong to any company?
                    <Typography
                      as="a"
                      href=""
                      onClick={(e: any) => {
                        e.preventDefault();
                        handleOpen();
                      }}
                      variant="small"
                      color="blue"
                      className="ml-1 font-bold"
                    >
                      Click here
                    </Typography>
                  </Typography>
                  <div className="flex justify-center">
                    <a onClick={() => handleSignOut()} className="link">
                      Logout
                    </a>
                  </div>
                </CardFooter>
              </Card>
            </div>
          </div>

          <div
            className={classNames(
              "absolute bottom-0 z-10 m-auto w-full text-center",
              outerHeight > 900 ? "mt-20" : "mt-0",
            )}
          >
            <hr className="border-gray-300 dark:border-gray-800" />
            <div
              className={`flex flex-wrap items-center justify-center gap-2 md:justify-between ${
                // @ts-ignore
                outerHeight > 900 ? "h-[30vh]" : "h-[20vh]"
                }`}
              style={{ backgroundImage: "url('/img/pastedimage-24d-400h.png')" }}
            >
              <div className="w-full py-1 text-center text-sm font-medium text-gray-500">
                Copyright &copy; {year} ZigOps Payroll
              </div>
            </div>
          </div>
        </div>
      )}

      <Dialog
        open={open}
        handler={handleOpen}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
      >
        <DialogHeader>Its a simple dialog.</DialogHeader>
        <DialogBody divider>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Accusamus ad reprehenderit omnis
          perspiciatis aut odit! Unde architecto perspiciatis, dolorum dolorem iure quia saepe autem
          accusamus eum praesentium magni corrupti explicabo!
        </DialogBody>
        <DialogFooter>
          <Button variant="text" color="red" onClick={handleOpen} className="mr-1">
            <span>close</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default SelectCompany;
