import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CustomerCodeFundingMethodFormData {
    customerPhoneNumber: string;
    customerName: string;
    customerPhoneNumberError: string;
    customerNameError: string;
    customerCode: string;
}

const initialState: CustomerCodeFundingMethodFormData = {
    customerPhoneNumber: "+260",
    customerName: "",
    customerPhoneNumberError: "",
    customerNameError: "",
    customerCode: ""
};

const customerCodeFundingMethodFormDataSlice = createSlice({
    name: "customerCodeFundingMethodFormData",
    initialState,
    reducers: {
        setCustomerPhoneNumber(state, action: PayloadAction<string>) {
            state.customerPhoneNumber = action.payload;
        },
        setCustomerName(state, action: PayloadAction<string>) {
            state.customerName = action.payload;
        },
        setCustomerPhoneNumberError(state, action: PayloadAction<string>) {
            state.customerPhoneNumberError = action.payload;
        },
        setCustomerNameError(state, action: PayloadAction<string>) {
            state.customerNameError = action.payload;
        },
        setCustomerCode(state, action: PayloadAction<string>) {
            state.customerCode = action.payload;
        },
        clearFormData(state) {
            state.customerPhoneNumber = "+260";
            state.customerName = "";
            state.customerPhoneNumberError = "";
            state.customerNameError = "";
            state.customerCode = "";
        },
    },
});

export const {
    setCustomerPhoneNumber,
    setCustomerName,
    setCustomerPhoneNumberError,
    setCustomerNameError,
    setCustomerCode,
    clearFormData,
} = customerCodeFundingMethodFormDataSlice.actions;

export default customerCodeFundingMethodFormDataSlice.reducer;