const InfoIcon = ({ color, width, height }) => {
    return (<svg
        className="material-symbols-info-outline"
        width={width ?? 16}
        height={height ?? 16}
        viewBox="0 0 16 16"
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M7.33333 11.3334H8.66666V7.33337H7.33333V11.3334ZM8 6.00004C8.18888 6.00004 8.34733 5.93604 8.47533 5.80804C8.60333 5.68004 8.66711 5.52182 8.66666 5.33337C8.66666 5.14449 8.60266 4.98626 8.47466 4.85871C8.34666 4.73115 8.18844 4.66715 8 4.66671C7.81111 4.66671 7.65288 4.73071 7.52533 4.85871C7.39777 4.98671 7.33377 5.14493 7.33333 5.33337C7.33333 5.52226 7.39733 5.68071 7.52533 5.80871C7.65333 5.93671 7.81155 6.00049 8 6.00004ZM8 14.6667C7.07777 14.6667 6.21111 14.4916 5.4 14.1414C4.58888 13.7912 3.88333 13.3163 3.28333 12.7167C2.68333 12.1167 2.20844 11.4112 1.85866 10.6C1.50888 9.78893 1.33377 8.92226 1.33333 8.00004C1.33333 7.07782 1.50844 6.21115 1.85866 5.40004C2.20888 4.58893 2.68377 3.88337 3.28333 3.28337C3.88333 2.68337 4.58888 2.20849 5.4 1.85871C6.21111 1.50893 7.07777 1.33382 8 1.33337C8.92222 1.33337 9.78888 1.50849 10.6 1.85871C11.4111 2.20893 12.1167 2.68382 12.7167 3.28337C13.3167 3.88337 13.7918 4.58893 14.142 5.40004C14.4922 6.21115 14.6671 7.07782 14.6667 8.00004C14.6667 8.92226 14.4916 9.78893 14.1413 10.6C13.7911 11.4112 13.3162 12.1167 12.7167 12.7167C12.1167 13.3167 11.4111 13.7918 10.6 14.142C9.78888 14.4923 8.92222 14.6672 8 14.6667ZM8 13.3334C9.48888 13.3334 10.75 12.8167 11.7833 11.7834C12.8167 10.75 13.3333 9.48893 13.3333 8.00004C13.3333 6.51115 12.8167 5.25004 11.7833 4.21671C10.75 3.18337 9.48888 2.66671 8 2.66671C6.51111 2.66671 5.24999 3.18337 4.21666 4.21671C3.18333 5.25004 2.66666 6.51115 2.66666 8.00004C2.66666 9.48893 3.18333 10.75 4.21666 11.7834C5.24999 12.8167 6.51111 13.3334 8 13.3334Z"
            fill={`${color ?? "#262626"}`}
        />
    </svg>
    );
}

export default InfoIcon;