
/**
 * Defines the regular expression for a valid full name of the form First Name MiddleName(Optional) LastName 
 * where the first, middle, and last name are separated by a space e.g. John Doe and John Dowel Doe
 * - Up to 4 names are allowed
 * - Hyphenated names are allowed
 * - Names with apostrophes are allowed
 */
export const FULL_NAME_REGEX = /^[a-zA-Z'-]+(?: [a-zA-Z'-]+){0,3}$/;

/**
 * Defines Zambian phone numbers regex's.
 */
export const ZAMBIAN_PHONE_NUMBER_REGEXS = {
    MTN: /^(?:\+260)(96|76)\d{7}$/,
    Airtel: /^(?:\+260)(97|77)\d{7}$/,
    Zamtel: /^(?:\+260)95\d{7}$/
};
