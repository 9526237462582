import { server } from "@/config/server";
import getHeaders from "./headers";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const ledgerApiSlice = createApi({
    reducerPath: "LedgerApi",
    baseQuery: fetchBaseQuery({
        baseUrl: server,
        prepareHeaders: getHeaders,
    }),
    tagTypes: ["Ledger"],
    endpoints: (builder) => ({
        getMostRecentLedgerEntryByTenantId: builder.query<any, any>({
            query: ({ tenantId }) => {
                return {
                    url: `/ledger/${tenantId}/most-recent`,
                    method: "GET"
                };
            },
        })
    }),
});

export const { useGetMostRecentLedgerEntryByTenantIdQuery } = ledgerApiSlice;