import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { server } from "@/config/server";
import getHeaders from "./headers";
import { RequestPaginationCursor, ResponsePaginationCursor } from "@/types/Pagination";
import { Employee, EmployeeActivityStatus, UpdateEmployeeStatus } from "./models/employee.model";
import { buildPaginationQueryString } from "@/utils/pagination";

export const EmployeesApiSlice = createApi({
  reducerPath: "EmployeesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: server,
    prepareHeaders: getHeaders,
  }),
  refetchOnReconnect: true,
  tagTypes: ["Employees"],
  endpoints: (builder) => ({
    createDomesticEmployee: builder.mutation<any, any>({
      query: (formData) => {
        return {
          url: `/employees/domestic`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: [{ type: "Employees", id: "employeeId" }],
    }),
    getEmployees: builder.query<any, any>({
      query: ({ companyId, previousCursor, nextCursor, itemsPerPage, page = "next", email }) => {
        let queryParams = buildPaginationQueryString({ previousCursor, nextCursor, itemsPerPage, page });
        if (email) {
          queryParams += `&email=${email}`;
        }
        return {
          url: `companies/${companyId}/employees?${queryParams}`,
          method: "GET",
        };
      },
      providesTags: [{ type: "Employees", id: "employeeId" }],
    }),
    getEmployeesByTenantIdAndStatus: builder.query<any, any>({
      query: ({ tenantId, status, previousCursor, nextCursor, itemsPerPage, page }) => {
        var requestPaginationCursor: RequestPaginationCursor = {
          previousCursor: previousCursor,
          nextCursor: nextCursor,
          itemsPerPage: itemsPerPage,
          page: page
        };
        return {
          url: `companies/${tenantId}/employees/get-by-status?status=${status}&${buildPaginationQueryString(requestPaginationCursor)}`,
          method: "GET",
        };
      },
      providesTags: (result, error, { status }) => [
        { type: "Employees", id: `get-by-status-${status}` }
      ],
    }),
    getEmployeeById: builder.query<any, string>({
      query: (employeeId) => {
        return {
          url: `employees/${employeeId}`,
          method: "GET",
        };
      },
      providesTags: (response, error, employeeId) => [{ type: "Employees", id: `get-employee-by-employee-id-${employeeId}` }],
    }),
    getEmployeesByIds: builder.query<ResponsePaginationCursor<Employee>, any>({
      query: ({ employeeIds, nextCursor, previousCursor, itemsPerPage, page }) => {
        return {
          url: `employees/get-by-ids?nextCursor=${nextCursor}&previousCursor=${previousCursor}&itemsPerPage=${itemsPerPage}&page=${page}`,
          method: "POST",
          body: employeeIds
        }
      }
    }),
    addEmployee: builder.mutation<any, any>({
      query: (employeeInfo) => {
        return {
          url: `employees`,
          method: "POST",
          body: employeeInfo,
        };
      },
      invalidatesTags: [
        { type: "Employees", id: "employeeId" },
        { type: "Employees", id: `get-by-status-${EmployeeActivityStatus.Active}` }
      ],
    }),
    updateEmployeeBasicInfo: builder.mutation<any, any>({
      query: (employeeInfo) => {
        return {
          url: `employees/update-basic-info`,
          method: "POST",
          body: employeeInfo,
        };
      },
      invalidatesTags: (response, error, { employeeId }) => [
        { type: "Employees", id: "employeeId" },
        { type: "Employees", id: `get-employee-by-employee-id-${employeeId}` }
      ],
    }),
    updateEmployeePhysicalAddress: builder.mutation<any, any>({
      query: (employeeInfo) => {
        return {
          url: `employees/update-home-address`,
          method: "POST",
          body: employeeInfo,
        };
      },
      invalidatesTags: (response, error, employeeAddress) => [
        { type: "Employees", id: `get-employee-by-employee-id-${employeeAddress.employeeId}` }
      ],
    }),
    updateEmployeeHomeAddress: builder.mutation<any, any>({
      query: (homeInfo) => {
        return {
          url: `employees/home-address/${homeInfo.employeeId}`,
          method: "PUT",
          body: homeInfo,
        };
      },
      invalidatesTags: (response, error, employeeAddress) => [
        { type: "Employees", id: `get-employee-by-employee-id-${employeeAddress.employeeId}` }
      ],
    }),

    updateEmployeePensionDetails: builder.mutation<any, any>({
      query: (info) => {
        return {
          url: `employees/pension-details/${info.employeeId}`,
          method: "PUT",
          body: info,
        };
      },
      invalidatesTags: (response, error, employeePensionDetails) => [
        { type: "Employees", id: `get-employee-by-employee-id-${employeePensionDetails.employeeId}` }
      ],
    }),

    updateEmployeeTpinAndNRC: builder.mutation<any, any>({
      query: (info) => {
        return {
          url: `employees/tax-id/${info.employeeId}`,
          method: "PUT",
          body: info.data,
        };
      },
      invalidatesTags: (response, error, tpinAndNrcInfo) => [
        { type: "Employees", id: `get-employee-by-employee-id-${tpinAndNrcInfo.employeeId}` }
      ],
    }),
    updateEmployee: builder.mutation<any, Employee>({
      query: (employee: Employee) => {
        return {
          url: "employees",
          method: "PUT",
          body: employee
        }
      },
      invalidatesTags: (response, error, employee) => [
        { type: "Employees", id: "employeeId" },
        { type: "Employees", id: `get-employee-by-employee-id-${employee.employeeId}` }
      ]
    }),
    updateEmployeeBankPaymentDetails: builder.mutation<any, any>({
      query: (bankPaymentDetails) => {
        return {
          url: `employees/update-bank-payment-details`,
          method: "POST",
          body: bankPaymentDetails,
        };
      },
      invalidatesTags: (response, error, bankPaymentDetails) => [
        { type: "Employees", id: "employeeId" },
        { type: "Employees", id: `get-employee-by-employee-id-${bankPaymentDetails.employeeId}` }
      ],
    }),
    updateEmployeeMobileMoneyPaymentDetails: builder.mutation<any, any>({
      query: (mobileMoneyPaymentDetails) => {
        return {
          url: `employees/update-mobile-money-payment-details`,
          method: "POST",
          body: mobileMoneyPaymentDetails,
        };
      },
      invalidatesTags: (response, error, mobileMoneyPaymentDetails) => {
        return [
          { type: "Employees", id: "employeeId" },
          { type: "Employees", id: `get-employee-by-employee-id-${mobileMoneyPaymentDetails.employeeId}` }
        ]
      },
    }),
    updateEmployeeStatus: builder.mutation<any, UpdateEmployeeStatus>({
      query: (updateEmployeeStatus) => {
        return {
          url: `employees/${updateEmployeeStatus.employeeId}/status`,
          method: "PATCH",
          body: updateEmployeeStatus
        };
      },
      invalidatesTags: (result, error, { employeeId, newStatus }) => [
        { type: "Employees", id: `get-by-status-${newStatus}` },
        { type: "Employees", id: `get-employee-by-employee-id-${employeeId}` }
      ],
    }),
    updateEmployeeNextOfKinInfo: builder.mutation<any, any>({
      query: (nextOfKinInfo) => {
        return {
          url: `employees/update-emergency-contact-details`,
          method: "POST",
          body: nextOfKinInfo
        }
      },
      invalidatesTags: (result, error, { employeeId }) => [
        { type: "Employees", id: `get-employee-by-employee-id-${employeeId}` }
      ]
    }),
    updateEmployeeCompensationInfo: builder.mutation<any, any>({
      query: (compensationInfo) => {
        return {
          url: `employees/${compensationInfo.employeeId}/compensation-details`,
          method: "PUT",
          body: compensationInfo
        }
      },
      invalidatesTags: (result, error, { employeeId }) => [
        { type: "Employees", id: "employeeId" },
        { type: "Employees", id: `get-employee-by-employee-id-${employeeId}` }
      ]
    }),
    updateEmployeePaymentMethod: builder.mutation<any, any>({
      query: (paymentMethod) => {
        return {
          url: `employees/${paymentMethod.employeeId}/payment-method`,
          method: "PUT",
          body: {
            paymentMethod: paymentMethod.paymentMethod
          }
        }
      },
      invalidatesTags: (result, error, { employeeId }) => [
        { type: "Employees", id: "employeeId" },
        { type: "Employees", id: `get-employee-by-employee-id-${employeeId}` }
      ]
    }),
    ///Adds a new employee
    createNewEmployee: builder.mutation<any, any>({
      query: (employeeDetails) => {
        return {
          url: `/employees`,
          method: "POST",
          body: employeeDetails,
        };
      },
      invalidatesTags: [{ type: "Employees", id: "employeeId" }],
    }),
  }),
});

export const {
  useGetEmployeesQuery,
  useLazyGetEmployeesQuery,
  useGetEmployeeByIdQuery,
  useGetEmployeesByTenantIdAndStatusQuery,
  useLazyGetEmployeeByIdQuery,
  useUpdateEmployeeBasicInfoMutation,
  useUpdateEmployeePhysicalAddressMutation,
  useCreateDomesticEmployeeMutation,
  useGetEmployeesByIdsQuery,
  useAddEmployeeMutation,
  useUpdateEmployeeHomeAddressMutation,
  useUpdateEmployeePensionDetailsMutation,
  useUpdateEmployeeTpinAndNRCMutation,
  useUpdateEmployeeMutation,
  useUpdateEmployeeBankPaymentDetailsMutation,
  useUpdateEmployeeMobileMoneyPaymentDetailsMutation,
  useCreateNewEmployeeMutation,
  useUpdateEmployeeStatusMutation,
  useUpdateEmployeeNextOfKinInfoMutation,
  useUpdateEmployeeCompensationInfoMutation,
  useUpdateEmployeePaymentMethodMutation
} = EmployeesApiSlice;
