import { RootState } from "@/redux/configureStore";
import { setCustomerName, setCustomerNameError } from "@/redux/features/payrollFunding/customer-code-funding-method-form-data";
import { Input, Typography } from "@zigops-material/react";
import { useDispatch, useSelector } from "react-redux";

const CustomerName = () => {
    const { customerName: payrollFundingName, customerNameError: payrollFundingNameError } =
        useSelector((state: RootState) => state.app_reducer.customerCodeFundingMethodFormData);
    const dispatch = useDispatch();

    const handleCustomerNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setCustomerName(e.currentTarget.value));
        dispatch(setCustomerNameError(""));
    }

    return (
        <div className="flex flex-col-reverse w-[100%] [@media(min-width:961px)]:flex-row h-[100%] [@media(min-height:900px)]:h-[80%]  gap-4">
            <div className="h-[100%] w-[100%] gap-4 p-5 [@media(min-width:461px)]:px-10 flex flex-col animate-fade-up rounded-md border border-gray-300 bg-white dark:border-gray-750 dark:bg-gray-850">
                <Typography className="text-2xl mt-10 text-gray-500">Enter the full name associated with the phone number you provided in the previous step</Typography>
                <div className="w-[100%]">
                    <Input
                        className="w-[100%] [@media(min-width:590px)]:w-[50%] text-gray-500 dark:text-gray-100"
                        value={payrollFundingName}
                        name="name"
                        onChange={handleCustomerNameChange}
                        variant="standard"
                        type="email"
                        placeholder="e.g. Annabelle Mbewe"
                        error={!!payrollFundingNameError}
                    />
                    {payrollFundingNameError &&
                        < Typography className="text-red-300">
                            {payrollFundingNameError}
                        </Typography>
                    }
                </div>
            </div>
        </div>
    );
};

export default CustomerName;