import { server } from "@/config/server";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getHeaders from "./headers";
import { CustomerCodeGenerationRequest } from "@/types/Tilt";

export const paymentProcessingApiSlice = createApi({
    reducerPath: "PaymentProcessingApi",
    baseQuery: fetchBaseQuery({
        baseUrl: server,
        prepareHeaders: getHeaders,
    }),
    tagTypes: ["PaymentProcessing", "Company"],
    endpoints: (builder) => ({
        generateCustomerCode: builder.mutation<CustomerCodeGenerationRequest, any>({
            query: (request) => {
                return {
                    url: `/tilt/payments/customer-code`,
                    method: "POST",
                    body: request,
                };
            },
            invalidatesTags: [{ type: "Company", id: "companies" }],
        })
    }),
});

export const { useGenerateCustomerCodeMutation } = paymentProcessingApiSlice;